import React from 'react';
import cx from 'classnames';
import componentMapper from "./componentMapper";
import { getSpacerClass } from '../utils/helpers';

const buildChildren = (data) => {
  if (data) {
    const { content } = data;
    return content.map(block => {
      const Component = componentMapper(block._type);
      return <Component data={block} parent="custom" />
    });
  }
  return null;
};

const TwoColumn = ({ data }) => {
  const { one, two, spacer } = data;

  return (
    <div className={cx('row', getSpacerClass(spacer))}>
       <div className="col-xs-18 col-md-offset-1 col-md-8">
         {buildChildren(one)}
       </div>
      <div className="col-xs-18 col-md-offset-1 col-md-8">
        {buildChildren(two)}
      </div>
    </div>
  )
};

export default TwoColumn;
