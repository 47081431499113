import React from 'react';
import cx from 'classnames';
import CTA from "./CTA";
import { getSpacerClass } from '../utils/helpers';

const BulletPointsList = ({ data }) => {
  const { heading, cta, points, spacer } = data;
  return (
    <div className={cx("shape-background shape-background-dots shape-background-left", getSpacerClass(spacer))}>
      <div className="row align-justify">
        <div className="col-xs-18 col-md-12 align-center text-center">
          <h2>{heading}</h2>
          <div className="row">
            <div className="col-xs-18">
              <ul className="list-bullets">
                { points.map((i, idx) => <li key={idx}>{i}</li>)}
              </ul>
            </div>
          </div>
          <p>
            <CTA {...cta} size="xl" />
          </p>
        </div>
      </div>
    </div>
  )
};

export default BulletPointsList;
