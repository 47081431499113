import React from "react";
import Img from "gatsby-image";
import { getFluidProps } from "../utils/image-url";

const Testimonial = ({ data }) => {
  const { text, author, organization, image } = data;
  return (
    <div className="section-spacer">
      <div className="row">
        <div className="col-xs-18 col-md-9 col-lg-8">
          <div className="shape-img-left">
            <Img fluid={getFluidProps(image.asset, 600)} alt={image.alt} />
          </div>
        </div>
        <div className="col-xs-18 col-md-9 align-justify">
          <p className="p no-margin">
            {text}
          </p>
          <p>
            <strong>
              {author} <br /> {organization}
            </strong>
          </p>
        </div>
      </div>
    </div>
  )
};

export default Testimonial;
