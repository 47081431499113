import React from 'react';
import Img from 'gatsby-image';
import { getFluidProps } from '../utils/image-url';

const MobileAppBlock = ({ data }) => {
  const { title, subtitle, image, androidUrl, iosUrl } = data;
  return (
    <div className="row">
      <div className="col col-sm-7 col-md-5 col-md-offset-3 hiw-phone">
        <Img fluid={getFluidProps(image.asset)} />
      </div>
      <div className="col-xs-18 col-sm-10 col-md-6 col-lg-5">
        <div className="hiw-spacer">
          <h2>{title}</h2>
          <p>
            {subtitle}
          </p>
          <p className="d-flex">
            <a
              href={iosUrl}
              style={{
                display: 'inline-block',
                overflow: 'hidden',
                background:
                  'url(https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg) no-repeat',
                width: 135,
                height: 40,
                backgroundSize: 'contain',
                margin: '10px 0',
              }}
            />
            <a
              href={androidUrl}
              style={{ display: 'inline-block', width: 156 }}
            >
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
              />
            </a>
          </p>
        </div>
      </div>
    </div>
  )
};

export default MobileAppBlock;
