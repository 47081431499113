import React from "react";
import cx from "classnames";
import BaseBlockContent from "@sanity/block-content-to-react";
import { getSpacerClass } from "../utils/helpers";
import serializers from "../utils/serializer";

const TextSection = ({ data, parent }) => {
  const { spacer, content, className } = data;
  const containerClass =
    parent !== "custom" &&
    !className &&
    "shape-background shape-background-dots shape-background-right";
  return (
    <div className={cx(getSpacerClass(spacer), containerClass, className)}>
      <BaseBlockContent blocks={content} serializers={serializers} />
    </div>
  );
};

export default TextSection;
