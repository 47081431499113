import React from "react";
import { buildImageObj } from "../utils/helpers";
import { imageUrlFor } from "../utils/image-url";

const MainImage = ({ data, mainImage, width = 900 }) => {
  const imgObj = data ? { asset: data.asset } : mainImage;
  const caption = data ? data.caption :  mainImage.alt;
  const w  = data && data.width || width;

  const imgUrl =
    imgObj &&
    imageUrlFor(buildImageObj(imgObj))
      .width(w)
      .height(Math.floor((9 / 16) * w))
      .fit("crop")
      .auto("format")
      .url();

  const style = data && data.width && { width: data.width };
  return imgUrl ? <img src={imgUrl} alt={caption || ""} style={style} /> : <></>;
};

export default MainImage;
