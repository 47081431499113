import React from 'react';

import MainImage from "./MainImage";
import TextBlocks from "./TextBlocks";
import BigText from "./BigText";
import Hero from "./Hero";
import LogoStrip from "./LogosStrip";
import BulletPointsList from "./BulletPointsList";
import BigTextSection from "./BigTextSection";
import StatsBlock from "./StatsBlock";
import ShapeMediaBlock from "./ShapeMediaBlock";
import ImpactSteps from "./ImpactSteps";
import ShapeImageBlock from "./ShapeImageBlock";
import Testimonials from "./Testimonials";
import Testimonial from "./Testimonial";
import IntegrationOptions from "./IntegrationOptions";
import IntegrationInfo from './IntegrationInfo';
import TextSection from "./TextSection";
import MobileAppBlock from './MobileAppBlock';
import EmbedSocial from "./EmbedSocial";
import ThreeColumn from "./ThreeColumn";
import TwoColumn from "./TwoColumn";
import FAQ from "./FAQ";

const componentMap = {
  mainImage: MainImage,
  textBlocks: TextBlocks,
  textSection: TextSection,
  bigText: BigText,
  hero: Hero,
  logosStrip: LogoStrip,
  bulletPointsList: BulletPointsList,
  bigTextSection: BigTextSection,
  statsBlock: StatsBlock,
  shapeMediaBlock: ShapeMediaBlock,
  shapeImageBlock: ShapeImageBlock,
  impactSteps: ImpactSteps,
  testimonials: Testimonials,
  testimonial: Testimonial,
  integrationOptions: IntegrationOptions,
  integrationInfo: IntegrationInfo,
  mobileAppBlock: MobileAppBlock,
  embedsocial: EmbedSocial,
  threeColumn: ThreeColumn,
  twoColumn: TwoColumn,
  faq: FAQ,
};

export default function(widgetName) {
  const component = componentMap[widgetName];
  if (component) return component;
  return () => (
    <div>Error! {widgetName} component not defined.</div>
  )
}
