import React from "react";
import BigText from "./BigText";
import { getSpacerClass } from "../utils/helpers";
import useVideo from "../hooks/useVideoPlayer";

const ShapeMediaBlock = ({ data }) => {
  const { heading, position, spacer, videoUrl, image, _key } = data;
  const id = "img" + _key;
  useVideo(`row-${_key}`);

  if (position === 'right') {
    return (
      <div className={getSpacerClass(spacer)}>
        <div className="row row-video" id={`row-${_key}`}>
          <div className="video-text col-xs-16 col-md-8 col-lg-8 col-md-offset-1 z-1">
            <BigText {...heading} />
          </div>
          <div className="cf shape-media">
            <div className="shape shape-video shape-right">
              <div className="video-play-button video-play-trigger">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131 131">
                  <g fill="none" fillRule="evenodd">
                    <path
                      fill="#FFF"
                      d="M49.3 39.8l42.5 21.7a5 5 0 0 1 0 9L49.2 91.2a5 5 0 0 1-7.2-4.5V44.2a5 5 0 0 1 7.3-4.4z"
                    />
                    <circle
                      cx="65.5"
                      cy="65.5"
                      r="64"
                      stroke="#FFF"
                      strokeWidth="3"
                    />
                  </g>
                </svg>
              </div>
              <div className="video-svg-container">
                <div className="video-container">
                  <iframe
                    allow="autoplay"
                    src={videoUrl}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  />
                </div>
                { position === 'right' ? (
                  <svg
                    className="video-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1240 700"
                    preserveAspectRatio="xMinYMin meet"
                  >
                    <defs>
                      <pattern
                        id={id}
                        height="100%"
                        width="100%"
                        x="0"
                        y="0"
                        patternUnits="objectBoundingBox"
                      >
                        <image
                          xlinkHref={image.asset.url}
                          x="0"
                          y="0"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="XMinYMin meet"
                        />
                      </pattern>
                    </defs>
                    <path
                      fill={`url(#${id})`}
                      fillRule="evenodd"
                      d="M294.817322 71.3435157C468.702839-22.8508337 783.4352-23.772073 1174.07232 68.5797979V583.938994C864.18247 705.762491 329.075173 752.870749 119.817322 621.343516c-227.552472-143.02616-109.75-395.75 175-550.0000003z"
                    />
                  </svg>
                ) : (
                  <svg
                    className="video-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 720 1080"
                    width="1080"
                    height="720"
                    preserveAspectRatio="xMinYMax meet"
                  >
                    <defs>
                      <pattern
                        id={id}
                        height="1"
                        width="1"
                        patternContentUnits="objectBoundingBox"
                      >
                        <image
                          xlinkHref={image.asset.url}
                          x="0"
                          y="0"
                          width="1"
                          height="1"
                          preserveAspectRatio="xMinYMin slice"
                        />
                      </pattern>
                    </defs>
                    <path
                      fill={`url(#${id})`}
                      fillRule="evenodd"
                      d="M247,4.5C582,-38.5,603.4,239.2,728,346.5C852.6,453.7,1008.5,523.6,931,675.5C853.6,827.4,702,904.5,443,905.5C285,906,132,873.6,1,790.8V79.2A771.6,771.6,0,0,1,247,4.5Z"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={getSpacerClass(spacer)}>
      <div className="row row-video" id={`row-${_key}`}>
        <div className="video-text col-xs-18 col-md-offset-5 col-md-13 col-lg-11 z-1">
          <BigText {...heading} />
        </div>
        <div className="cf shape-media col-xs-18 col-md-5 col-lg-7">
          <div className="shape shape-left shape-video">
            <div className="video-play-button video-play-trigger">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131 131">
                <g fill="none" fillRule="evenodd">
                  <path
                    fill="#FFF"
                    d="M49.3 39.8l42.5 21.7a5 5 0 0 1 0 9L49.2 91.2a5 5 0 0 1-7.2-4.5V44.2a5 5 0 0 1 7.3-4.4z"
                  />
                  <circle
                    cx="65.5"
                    cy="65.5"
                    r="64"
                    stroke="#FFF"
                    strokeWidth="3"
                  />
                </g>
              </svg>
            </div>
            <div className="video-svg-container">
              <div className="video-container">
                <iframe
                  allow="autoplay"
                  src={videoUrl}
                  width="1600"
                  height="900"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
              </div>
              <svg
                className="video-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 720 1080"
                width="1080"
                height="720"
                preserveAspectRatio="xMinYMax meet"
              >
                <defs>
                  <pattern
                    id={id}
                    height="1"
                    width="1"
                    patternContentUnits="objectBoundingBox"
                  >
                    <image
                      xlinkHref={image.asset.url}
                      x="0"
                      y="0"
                      width="1"
                      height="1"
                      preserveAspectRatio="xMinYMin slice"
                    />
                  </pattern>
                </defs>
                <path
                  fill={`url(#${id})`}
                  fillRule="evenodd"
                  d="M247,4.5C582,-38.5,603.4,239.2,728,346.5C852.6,453.7,1008.5,523.6,931,675.5C853.6,827.4,702,904.5,443,905.5C285,906,132,873.6,1,790.8V79.2A771.6,771.6,0,0,1,247,4.5Z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

};

export default ShapeMediaBlock;
