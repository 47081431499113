import React, { useLayoutEffect } from "react";

function standardLoad(e) {
  for (let i = 0; i < e.length; i++) {
    var t = e[i],
      o = t.getAttribute("data-ref");
    window.EMBEDSOCIALREVIEWS.getEmbedData(o, t);
  }
}

const EmbedSocial = ({ data }) => {
  const { widget_id, height } = data;

  useLayoutEffect(() => {
    standardLoad( document.getElementsByClassName("embedsocial-reviews"));
  }, []);

  return (
    <div>
      <div
        style={{ height }}
        className="embedsocial-reviews"
        data-ref={widget_id}
      />
    </div>
  );
};

export default EmbedSocial;
