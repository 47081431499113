import React from 'react';
import BaseBlockContent from '@sanity/block-content-to-react';
import serializers from "../utils/serializer";

const TextBlocks = ({ data }) => {
  const { blocks } = data;
  return (
    <BaseBlockContent blocks={blocks} serializers={serializers} />
  )
};

export default TextBlocks;
