import React, { useEffect } from 'react';

export default () => {
  function changeSlide(slide) {
    var slides = document.querySelectorAll('.'+slide);
    for (var i = 0, len = slides.length; i < len; i++) {
      (function(slide) {
        slides[i].classList.add('active');
      })(i)
    }
  }

  useEffect(() => {
    var testimonialSlider = document.querySelector('.testimonial-slider');

    var slides = testimonialSlider.querySelectorAll('.slide');
    var slideControls = document.querySelectorAll('.slider-control');
    const listeners = [];

    for (var i = 0, len = slides.length; i < len; i++) {
      (function(slide) {
        slides[i].classList.add('slide-'+i);
        slideControls[i].classList.add('slide-'+i);

        function handler(e) {
          var slideToShow = e.currentTarget;

          // Remove Active Class from all slides
          for (var ii = 0, len = slides.length; ii < len; ii++) {
            slides[ii].classList.remove('active');
            slideControls[ii].classList.remove('active');
          }

          // Find new slide and add active state
          for (var y = 0, l = slideToShow.classList.length; y < l; y++) {
            if(/slide-.*/.test(slideToShow.classList[y])) {
              var slideNumber = slideToShow.classList[y];
              changeSlide(slideNumber);
            }
          }
        }

        slideControls[i].addEventListener('click', handler, false);

        listeners.push([slideControls[i], handler]);

      })(i);
    }
    slides[0].classList.add('active');
    slideControls[0].classList.add('active');

    return () => {
      listeners.forEach(i => {
        i[0].removeEventListener('click', i[1]);
      });
      listeners.length = 0;
    };
  }, []);
};
