import React from "react";
import Img from "gatsby-image";
import cx from "classnames";
import CTA from "./CTA";
import BigText from "./BigText";
import { getSpacerClass } from "../utils/helpers";
import { getFluidProps } from "../utils/image-url";

const ShapeImageBlock = ({ data }) => {
  const { cta, heading, subtitle, image, position, spacer } = data;

  const textSectionClass =
    position === "left"
      ? "col-xs-18 col-sm-11 col-md-8 col-md-offset-2 align-justify"
      : "col-xs-18 col-sm-11 col-md-offset-2 col-md-9 col-lg-8 align-justify";
  const imgSectionClass =
    position === "left"
      ? "col-xs-18 col-sm-7 col-md-6"
      : "col-xs-18 col-sm-7 col-md-6 col-md-offset-1 col-lg-offset-2";

  const textSection = (
    <div className={textSectionClass}>
      <BigText {...heading} />
      <p className="p">{subtitle}</p>
      { cta && (
        <div className="text-link">
          <CTA {...cta} />
        </div>
      )}
    </div>
  );

  const imageSection = (
    <div className={imgSectionClass}>
      <div
        className={
          position === "left"
            ? "shape-img-left shape-big shape-background shape-background-dots shape-background-left"
            : "shape-img-right shape-big shape-background-dots"
        }
      >
        <Img fluid={getFluidProps(image.asset)} />
      </div>
    </div>
  );

  return (
    <div className={cx(
      "shape-background",
      getSpacerClass(spacer),
      position === 'right' ? 'shape-background-dots shape-background-right' : ''
    )}>
      <div className="row">
        {position === "left" ? (
          <>
            {imageSection}
            {textSection}
          </>
        ) : (
          <>
            {textSection}
            {imageSection}
          </>
        )}
      </div>
    </div>
  );
};

export default ShapeImageBlock;
