import React from 'react';
import cx from "classnames";
import Img from 'gatsby-image';
import CTA from "./CTA";
import { getSpacerClass } from "../utils/helpers";
import { getFluidProps } from '../utils/image-url';

const ThreeColumn = ({ data }) => {
  const { spacer, content } = data;
  return (
    <div className={getSpacerClass(spacer)}>
      <div className="row">
        { content.map((c, idx) => (
          <div className={cx("col-xs-18 col-sm-5 col-md-4 col-sm-offset-1 align-justify", idx === 0 && 'col-md-offset-2')}>
            <Img fluid={getFluidProps(c.illustration.image.asset)} />
            <h3 className="no-margin">{c.heading}</h3>
            <p>{c.tagline}</p>
            <p className="text-center" style={{ marginTop: 'auto' }}>
              <CTA {...c.cta} />
            </p>
          </div>
        ))}
      </div>
    </div>
  )
};

export default ThreeColumn;
