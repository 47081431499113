import React from "react";
import cx from "classnames";
import BigText from "./BigText";
import CTA from "./CTA";
import { getSpacerClass } from "../utils/helpers";

const BigTextSection = ({ data }) => {
  const { spacer, subheading, subheadingClass, heading, cta, align, fullWidth } = data;

  const dotClass =
    !cta &&
    !subheading &&
    align !== "center" &&
    "shape-background-dots-big shape-background-left";

  return (
    <div className={cx("shape-background", dotClass)}>
      <div className={getSpacerClass(spacer)}>
        <div
          className={cx("row", {
            "text-center": align === "center",
          })}
        >
          <div
            className={
              fullWidth
                ? "col"
                : align === "center"
                ? "col-xs-18 col-md-10 col-md-offset-4"
                : "col-xs-18 col-md-16 col-md-offset-1"
            }
          >
            <BigText {...heading} />
            {cta && (
              <p>
                <CTA {...cta} size="xl" />
              </p>
            )}
          </div>
        </div>
        {subheading && (
          <div
            className={cx("row", {
              "text-center": align === "center",
            })}
          >
            <div
              className={
                fullWidth
                  ? "col"
                  : cx("col-xs-18 col-md-13 col-lg-11 col-md-offset-1", align === "center" && 'col-md-offset-2')
              }
            >
              <p className={subheadingClass || "subheading"}>{subheading}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BigTextSection;
