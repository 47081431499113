import React from "react";
import Img from "gatsby-image";
import CTA from "./CTA";
import { getFluidProps } from "../utils/image-url";

const IntegrationOptions = ({ data }) => {
  const { options } = data;
  return options.map((i) => (
    <div className="row" key={i._key}>
      <div className="col-xs-18 col-sm-2 col-md-offset-1">
        <Img
          fluid={getFluidProps(i.illustration.image.asset)}
          alt={i.illustration.image.caption}
        />
      </div>
      <div className="col-xs-18 col-sm-16 col-md-12">
        <h2>{i.heading}</h2>
        <p>{i.tagline}</p>
        <div className="text-link">
          <CTA {...i.cta} />
        </div>
      </div>
    </div>
  ));
};

export default IntegrationOptions;
