import React from "react";
import cx from 'classnames';

const colorMap = {
  primary: 'text-blue',
  textPrimary: ''
}

const BigText = ({ text, color, size }) => {
  const classes = cx(
    colorMap[color],
    size
  );

  return <h2 className={classes} style={{ whiteSpace: 'break-spaces'}}>{text}</h2>
}

export default BigText;
