import React from "react";
import BlockContent from '@sanity/block-content-to-react';
import CTA from "../components/CTA";
import { buildImageObj } from "./helpers";
import { imageUrlFor } from "./image-url";

const serializers = {
  types: {
    block: (props) => {
      if (props.node.style === 'lead') {
        const text = props.node.children[0].text;
        return <p className="p">{text}</p>
      }
      return BlockContent.defaultSerializers.types.block(props)
    },
    mainImage: ({ node }) => {
      const { url, width, height, caption, alt } = node;
      console.log(node)
      const imgUrl =
        imageUrlFor(buildImageObj(node))
          .width(width)
          .height(height)
          .fit("crop")
          .auto("format")
          .url();

      const imgEl = <img src={imgUrl} alt={alt ||  caption} />;
      if (url) {
        return (
          <div className="text-center">
            <a href={url}>
              {imgEl}
            </a>
            { caption && <small className="text-grey">{caption}</small>}
          </div>
        )
      }
      return (
        <div className="text-center">
          {imgEl}
          { caption && <small>{caption}</small>}
        </div>
      )
    },
  },
  marks: {
    "aligncenter": ({ mark, children }) => {
      return <span className="text-center">{children}</span>;
    },
    link: (props) => {
      return (
        <CTA noArrow className="allcaps" title={props.children} route={props.mark.href} />
      );
    }
  }
};

export default serializers;
