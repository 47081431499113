import React from 'react';
import TextBlocks from "./TextBlocks";

const FAQ = ({ data }) => {
  const { title, subtitle, questions } = data;
  return (
    <div className="row">
      <div className="col-xs-18 col-md-offset-1 col-md-8">
        <h2 className="xl text-blue">{title}</h2>
        <p>
          {subtitle}
        </p>
        { questions.map(q => (
          <>
            <h3>{q.question}</h3>
            <TextBlocks data={{ blocks: q.answer }} />
          </>
        ))}
      </div>
    </div>
  )
};

export default FAQ;
