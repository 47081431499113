import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import cx from "classnames"
import CTA from "./CTA"
import BigText from "./BigText"
import MainImage from "./MainImage"
import { getFluidProps } from "../utils/image-url"
import { getSpacerClass } from "../utils/helpers"

const ImpactSteps = ({ data }) => {
  const { heading, stats, spacer } = data
  return (
    <div
      className={cx(
        getSpacerClass(spacer),
        "shape-background shape-background-dots shape-background-right"
      )}
    >
      <div className="row align-justify">
        <div className="col-xs-18 col-md-10 col-lg-9 col-xl-7 align-center text-center">
          <h2>{heading}</h2>
        </div>
      </div>
      <div className="row">
        {stats.map((i, idx) => (
          <div
            key={i._key}
            className={cx("impact-step col-xs-18 col-sm-6 col-md-4", {
              "col-md-offset-3": idx === 0,
            })}
          >
            <div className="impact-step-img">
              <Img
                fluid={getFluidProps(i.illustration.image.asset)}
                alt={i.illustration.image.alt}
              />
            </div>
            <h3>{i.heading}</h3>
            <p>{i.tagline}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ImpactSteps
