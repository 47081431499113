import React, { useLayoutEffect } from 'react';

export default (id) => {
  useLayoutEffect(() => {
    const Vimeo = window.Vimeo;
    const TweenLite = window.TweenLite;
    var videoEndShape = 'M0 0h1200v670H0z';
    var playButtonStartingShape =
      'M49.3 39.8l42.5 21.7a5 5 0 0 1 0 9L49.2 91.2a5 5 0 0 1-7.2-4.5V44.2a5 5 0 0 1 7.3-4.4z';
    var playButtonEndShape =
      'M65 58l37-37 8 9-37 36 36 36-8 8-36-36-36 36-8-8 36-36-37-36 9-9 36 37z';


    const el = document.getElementById(id);
    if (!el) return;
    const listeners = [];
    // NodeList older browser support

    var videoOpen = false;
    var videoShapePath = el
      .querySelectorAll('svg')[1]
      .querySelector('path')
      .getAttribute('d');
    var videoPlay = el.querySelector('.video-play-trigger');
    var checkVideo = function(v) {
      videoOpen = !videoOpen;
      v.classList.toggle('video-open');
    };

    function handler() {
      var videoRow = this.parentNode.parentNode.parentNode;
      var textHeight = videoRow.querySelector('.video-text')
        .firstElementChild.offsetHeight;
      var video = videoRow.querySelector('.shape-video');
      var videoPlaySVG = this.querySelector('svg').querySelector('path');
      var iframe = videoRow.querySelector('iframe');
      var player = new Vimeo.Player(iframe);

      if (!videoShapeSVG) {
        var videoShapeSVG = videoRow
          .querySelectorAll('svg')[1]
          .querySelector('path');
      }
      if (videoOpen) {
        player.pause();

        TweenLite.to(videoShapeSVG, 1, {
          morphSVG: videoShapePath,
          onComplete: checkVideo(videoRow),
        });
        TweenLite.to(videoPlaySVG, 1, {
          morphSVG: playButtonStartingShape,
        });

        video.style.top = '';
        video.style.height = '';
      } else {
        player.play();
        TweenLite.to(videoShapeSVG, 1, {
          morphSVG: videoEndShape,
          onComplete: checkVideo(videoRow),
        });
        TweenLite.to(videoPlaySVG, 1, { morphSVG: playButtonEndShape });

        if (document.documentElement.clientWidth > 1040) {
          video.style.top = textHeight * 1.2 + 'px';
          video.style.height =
            Math.max(textHeight * 2.1, iframe.offsetHeight * 1.7) + 'px';
        }
      }
    }

    videoPlay.addEventListener('click', handler, false);

    listeners.push([videoPlay, handler]);

    return () => {
      listeners.forEach((i) => {
        i[0].removeEventListener('click', i[1]);
      });
      listeners.length = 0;
    };
  }, []);
};
