import React from "react";
import Img from "gatsby-image";
import cx from "classnames";
import BigText from "./BigText";
import CTA from "./CTA";
import { getSpacerClass } from "../utils/helpers";
import { getFluidProps } from "../utils/image-url";

const IntegrationInfo = ({ data }) => {
  const { title, subtitle, image, video, align, spacer, cta } = data;

  function autoPlay() {
    var v = this;
    setTimeout(function() {
      v.play();
    }, 300);
  }

  return (
    <div className={getSpacerClass(spacer)}>
      <div className={align === 'right' ? "row reverse" : "row"}>
        <div className="col-xs-18 col-md-7 col-md-offset-1">
          { video ? (
            <video
              playsinline
              autoPlay
              loop
              muted
              poster={image?.asset.url}
              style={{ width: '100%' }}
              title={image?.alt}
              onEnded={autoPlay}
            >
              <source src={video.asset.url} type="video/mp4"/>
            </video>
          ) : (
            <Img fluid={getFluidProps(image.asset)} alt={image.alt} />
          )}
        </div>
        <div className={cx("col-xs-18 col-md-8", (align === 'right' || !video) && 'col-md-offset-1')}>
          <BigText {...title} />
          <p className="p" style={{ whiteSpace: 'break-spaces' }}>
            {subtitle}
          </p>
          { cta && (
            <div className="text-link">
              <CTA {...cta} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
};

export default IntegrationInfo;
