import React from "react";
import cx from "classnames";
import { Link } from "gatsby";
import Img from "gatsby-image";
import MainImage from "./MainImage";
import { getFluidProps } from "../utils/image-url";
import { getSpacerClass } from "../utils/helpers";

const LogoStrip = ({ data }) => {
  const { logos, spacer, caption } = data;
  return (
    <div className={getSpacerClass(spacer)}>
      <div className="row text-center logos-row around-xs around-sm around-md around-lg">
        {logos.map((i) => (
          <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3">
            <Img fluid={getFluidProps(i.asset, 300)} />
          </div>
        ))}
      </div>
      {caption && (
        <div className="row">
          <div className="col text-center">
            <p>{caption}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogoStrip;
