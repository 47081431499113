import React from "react";
import cx from "classnames";
import Img from "gatsby-image";
import { getFluidProps } from "../utils/image-url";
import useSlider from "../hooks/useSlider";

const Testimonials = ({ data }) => {
  const { testimonials } = data;
  useSlider();

  return (
    <>
      <div>
        <div className="testimonial-slider">
          {testimonials.map((i, idx) => (
            <div className={`row slide slide-${idx}`}>
              <div className="col-xs-18 col-md-9 col-lg-8">
                <div className="shape-img-left">
                  <Img fluid={getFluidProps(i.image.asset, 600)} alt={i.image.alt} />
                </div>
              </div>
              <div className="col-xs-18 col-md-9 align-justify">
                <p className="p no-margin">{i.text}</p>
                <p>
                  <strong>
                    {i.author} <br /> {i.organization}
                  </strong>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row text-center testimonial-slider-controls">
        {testimonials.map((i, idx) => (
          <div
            key={i._key}
            className={cx(
              "slider-control col-xs-9 col-md-4 align-justify",
              `slide-${idx}`,
              idx === 0 && 'col-md-offset-1'
            )}
          >
            <div className="text-center">
              <Img
                imgStyle={{ objectFit: 'contain' }}
                style={{ maxHeight: 120 }}
                fluid={getFluidProps(i.logo.asset)}
                alt={i.logo.alt}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Testimonials;
